import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import {
  Link,
  Typography,
} from '@material-ui/core'

const Prepaid = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='prepaid-0'
        headline='Was ist die Zahlungsoption Vorkasse?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Mit der Zahlungsoption Vorkasse kannst du ein Guthaben in regiomove hinterlegen. Mit dem Guthaben kannst du ganz bequem KVV-Fahrkarten kaufen.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='prepaid-2'
        headline='Können auch Jugendliche / Kinder unter 18 Jahren die Zahlungsoption Vorkasse nutzen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Auch auf dem regiomove Konto einer anderen Person oder deiner Kinder kannst du unter Vorkasse, Guthaben hinterlegen, sodass diese ganz einfach KVV-Fahrkarten kaufen können, immer dann, wenn sie diese benötigen.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='prepaid-3'
        headline='Mit welchen Zahlungsmitteln kann ich mein Vorkasse Konto aufladen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Um das Guthaben sofort nutzen zu können, empfiehlt sich Giropay, es ist aber auch eine Überweisung per SEPA möglich.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='prepaid-4'
        headline='Wie lade ich mein Vorkasse Guthaben auf?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Sie können jederzeit in Ihrem Kundenkonto das Vorkasse Guthaben über die angebotenen Zahlungsmöglichkeiten aufladen. Hierfür stehen Ihnen verschiedene Möglichkeiten zur Verfügung:
              <ul>
                <li>
                  SEPA-Überweisung<br />
                  Durch Nutzung von Vorkasse mittels SEPA-Überweisung können Sie ihr Guthaben, welches für den Ausgleich ihrer künftigen Zahlungsverpflichtungen aus Ticketkäufen genutzt wird, bei LOGPAY aufladen. Nach Auswahl dieses Verfahrens und des zu zahlenden Betrages erhalten Sie eine E-Mail, welche die für die SEPA-Überweisung notwendigen Informationen (Kontoverbindung der LOGPAY, Betrag und Verwendungszweck) enthält.
                </li>
                <li>
                  giropay<br />
                  Durch Nutzung von giropay werden Sie auf die giropay-Checkoutseite weitergeleitet, auf der Sie sich zwischen einer Online-Überweisung oder einer Aufladung mit Ihrem giropay-Account entscheiden. Voraussetzung für die Teilnahme an giropay ist die Teilnahme Ihres Zahlungsdienstleisters am giropay-Verfahren. Ferner muss das Onlinebanking-Verfahren bei Ihrem Zahlungsdienstleister zugelassen sein.
                </li>
              </ul>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='prepaid-5'
        headline='Wie lade ich das Vorkasse Guthaben für eine andere Person (meine Kinder) auf?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Sie können jederzeit in Ihrem Kundenkonto das Vorkasse Guthaben über die angebotenen Zahlungsmöglichkeiten aufladen. Hierfür stehen Ihnen verschiedene Möglichkeiten zur Verfügung:
              <ul>
                <li>SEPA-Überweisung</li>
                <li>giropay</li>
              </ul>
              P.S. Die Aufladungsmöglichkeit unterscheidet sich vom Punkt 4 nicht
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='prepaid-6'
        headline='Wann ist mein Guthaben in der regiomove App sichtbar?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Wenn das Guthaben über Giropay aufgeladen wird, kann man sofort darüber verfügen. Bei einer eigenständigen SEPA-Überweisung dauert es max. 5 Bankarbeitstage. Unsere Kontonummer sowie den Verwendungszweck teilen wir per E-Mail dem registrierten Nutzer der App mit, sobald Vorkasse als Zahlart hinterlegt ist.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='prepaid-7'
        headline='Ich habe noch Vorkasseguthaben, das ich nicht mehr brauche, kann ich es mir wieder ausbezahlen lassen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Gerne können Sie sich das Restguthaben jederzeit auszahlen lassen. Hierzu nehmen Sie bitte Kontakt mit dem LOGPAY Kundensupport auf.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='prepaid-8'
        headline='An wen kann ich mich wenden, wenn ich Fragen zur Zahlung habe?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Unser Zahlungsanbieter LOGPAY hält alle weiteren Antworten zu deinen Fragen unter folgendem Link bereit:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link
                target='_blank'
                rel='noopener'
                href='https://faq.logpay.de/'
              >
                https://faq.logpay.de/
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              Du erreichst den LOGPAY-Kundenservice unter:<br />
              Tel: +49 6196 8012 702<br />
              E-Mail: <Link href='mailto:kundenservice@logpay.de'> Kundenservice@logpay.de</Link>
            </Typography>

          </>
        }
      />
    </>
  )
}

Prepaid.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default Prepaid
