import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import {
  Link,
  Typography,
} from '@material-ui/core'

const BookingBikebox = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingBikebox-0'
        headline='Was sind „B+R Boxen“?'
        content={
          <>
            <Typography paragraph variant='caption'>
              In der App werden die Bikeboxen und Gepäckschließfächer von Kienzler
              angezeigt, die an mehreren Standorten im KVV-Gebiet zu finden sind, unter
              anderem an mehreren regiomove-Ports. Die Boxen können tages-, wochen- oder
              monatsweise angemietet werden. Die Schließfachanlagen sind mit Steckdosen
              ausgestattet und die Bikeboxen bieten zum Teil eine Lademöglichkeit für E-Bikes.
              Um die Boxen mieten zu können, muss man sich auf der folgenden Webseite registrieren:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://www.bikeandridebox.de/'>
                https://www.bikeandridebox.de/
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              Dort findest du auch alle Infos zu den Standorten und Tarifen.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingBikebox-1'
        headline='Kann ich die Boxen in der regiomove App buchen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Nein. In der App kannst du die Boxen finden und dir die Verfügbarkeit anzeigen
              lassen.
            </Typography>
            <Typography paragraph variant='caption'>
              Wenn du ein Schließfach buchen möchtest, gelangst du über die App zur
              Buchungsseite des Anbieters. Hier steht wie’s geht:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://www.bikeandridebox.de/so-gehts'>
                https://www.bikeandridebox.de/so-gehts
              </Link>
            </Typography>
          </>
        }
      />
    </>
  )
}

BookingBikebox.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default BookingBikebox
