import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import {
  Link,
  Typography,
} from '@material-ui/core'

const General = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='general-0'
        headline='Was ist regiomove?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Unterschiedliche Mobilitätsangebote – vernetzte Verkehrsmittel – alles
              aus einer Hand.
            </Typography>
            <Typography paragraph variant='caption'>
              Alles in einer App klingt nach Übertreibung, ist es aber nicht. Denn
              regiomove bündelt alles, was es braucht, um im Verbundgebiet des KVV von
              A nach B zu kommen. Dabei werden erstmals die Verkehrsmittel miteinander
              vernetzt: Bus, Bahn, Bike Sharing und Car Sharing, nach und nach sollen
              alle Mobilitätsangebote integriert werden.
            </Typography>
            <Typography paragraph variant='caption'>
              Der große Vorteil für euch als unsere Kunden: Ihr müsst keine Accounts bei
              verschiedenen Anbietern haben. Einfach dein Ziel eingeben, die für dich
              beste Route finden und du kannst buchen.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='general-1'
        headline='Welche Angebote kann ich in regiomove nutzen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Aktuell sind folgende Partner über die regiomove-App buchbar:
            </Typography>
            <Typography paragraph variant='caption'>
              ÖPNV - KVV-Tickets
              <br />
              Car Sharing - stadtmobil
              <br />
              Bike Sharing - KVV.nextbike
              <br />
              E-Scooter Sharing - Voi
              <br />
              Weitere Anbieter werden nach und nach integriert und über die gängigen
              KVV-Kommunikationskanäle mitgeteilt. Weiterführende Informationen
              unserer Mobilitätsanbieter findest du hier:
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>KVV</strong>
              <br />
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/fahrkarten/allgemeine-informationen/faqs.html'>
                https://www.kvv.de/fahrkarten/allgemeine-informationen/faqs.html
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>stadtmobil</strong>
              <br />
              <Link target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/service/fragen-antworten/'>
                https://karlsruhe.stadtmobil.de/service/fragen-antworten/
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>KVV.nextbike</strong>
              <br />
              <Link target='_blank' rel='noopener' href='https://www.nextbike.de/de/faq/'>
                https://www.nextbike.de/de/faq/
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Voi</strong>
              <br />
              <Link target='_blank' rel='noopener' href='https://www.voiscooters.com/de/faq/'>
                https://www.voiscooters.com/de/faq/
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='general-2'
        headline='Was ist intermodal?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Mit regiomove kannst du intermodale Reiseketten buchen. D.h.
              verschiedene Mobilitätsangebote können entlang einer Reise kombiniert
              werden. Z.B. fährst du mit dem KVV.nextbike bis zum Hbf und dort mit
              dem Regionalexpress weiter bis Baden-Baden. Die App findet für dich die
              geeignetste (ggf. vernetzte) Verkehrsmittelkombination bis zum Ziel. Die
              Buchung und Abrechnung erfolgt ebenfalls innerhalb der App. Ein
              regiomove-Konto reicht dafür.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='general-3'
        headline='Was ist das regiomove-Gebiet?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Die Angebote in der regiomove-App sind im gesamten KVV-Gebiet nutzbar.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='general-4'
        headline='Was sind meine Vorteile als regiomove-Nutzer?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Mit nur einem Nutzerkonto bei regiomove kannst du alle
              Mobilitätsdienste aus einer Hand nutzen. Du musst keine Konten bei
              anderen Dienstleistern anlegen. Die App schlägt dir aus den Angeboten
              unserer Mobilitätspartner das für dich am meisten passende Verkehrsmittel
              vor. Sogar in Kombination! Bezahlen kannst du die genutzten Fahrten
              alle zentral über ein Bezahlmittel. Übersichtlich, einfach und bequem.
              Und sicher.
              <br />
              Regiomove ist eine eigene App des KVV. Der KVV ist für deine
              Datensicherheit verantwortlich. Solltest du Fragen haben, sind wir gerne
              dein erster Ansprechpartner.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='general-5'
        headline='Was ist die Datengrundlage für die berechneten CO2-Emissionen der dargestellten Verkehrsmittel?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Alle Emissionsfaktoren beziehen sich auf Emissionen pro Personenkilometer (kg CO2e/pkm) beziehungsweise Fahrzeugkilometer (kg CO2e/vkm). Grundlage der Emissionsfaktoren sind nur die direkten Emissionen! Bei der Berechnung werden nur die direkten Emissionen berücksichtigt, da nicht für alle Verkehrsträger Vorketten-Emissionen festgestellt werden können. Der Hauptteil der Emissionsfaktoren stammt aus der GEMIS-Datenbank, Version 5.0 (Stand 2019). GEMIS (Globales Emissions-Modell Integrierter Systeme) ist eine Datenbank mit Bilanzierungs- und Analysemöglichkeiten für Lebenszyklen von Energie-, Stoff- und Transportprozessen sowie ihrer beliebigen Kombination. Die Datenbank wird vom IINAS (Internationales Institut für Nachhaltigkeitsanalysen und -strategien) bereitgestellt.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='general-6'
        headline='Meine Frage konnte nicht beantwortet werden, wohin kann ich mich wenden?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Du kannst dich an die Kundenzentren der jeweiligen Anbieter
              wenden oder direkt beim KVV-Kundenservice anfragen:
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>KVV und regiomove:</strong>
              <br />
              <Link href='mailto:info@kvv.karlsruhe.de'>
                info@kvv.karlsruhe.de
              </Link>
              <br />
              <Link href='tel:+4972161075885'>0721 6107-5885</Link>
              <br />
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/service/kontakt/kontaktformular-anfrage.html'>
                https://www.kvv.de/service/kontakt/kontaktformular-anfrage.html
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>KVV.nextbike:</strong>
              <br />
              <Link href='mailto:kundenservice@nextbike.de'>
                kundenservice@nextbike.de
              </Link>
              <br />
              <Link href='tel:+493069205046'>030 69205046</Link>
              <br />
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>stadtmobil:</strong>
              <br />
              <Link href='mailto:info@karlsruhe.stadtmobil.de'>
                info@karlsruhe.stadtmobil.de
              </Link>
              <br />
              <Link href='tel:+497219119110'>0721 911911-0</Link>
              <br />
              <Link target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/kontakt/'>
                https://karlsruhe.stadtmobil.de/kontakt/
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Voi:</strong>
              <br />
              <Link href='mailto:support@voiapp.io'>
                support@voiapp.io
              </Link>
              <br />
              <Link target='_blank' rel='noopener' href='https://voi.kustomer.help/de/categories/faq-HJ2PRb2d_'>
                Voi FAQ
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Kienzler Bikeboxen:</strong>
              <br />
              <Link href='mailto:support@bikeandridebox.de'>
                support@bikeandridebox.de
              </Link>
              <br />
              <Link href='tel:+497831788900'>07831 788-900</Link>
              <br />
              <Link target='_blank' rel='noopener' href='https://www.bikeandridebox.de/hilfe '>
                https://www.bikeandridebox.de/hilfe
              </Link>
            </Typography>
          </>
        }
      />
    </>
  )
}

General.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default General
