import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import {
  Link,
  Typography,
} from '@material-ui/core'

const Privacy = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='privacy-0'
        headline='Was passiert mit meinen persönlichen Daten?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Der Schutz deiner persönlichen Daten ist uns ein wichtiges Anliegen. Wir
              behandeln deine personenbezogenen Daten vertraulich und entsprechend der
              gesetzlichen Datenschutzvorschriften. In unseren Datenschutzhinweisen kannst
              du alles genau nachlesen:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/unternehmen/rechtliche-hinweise/informationspflicht-nach-dsgvo/regiomove-datenschutzbestimmungen.html'>
                https://www.kvv.de/unternehmen/rechtliche-hinweise/informationspflicht-nach-dsgvo/regiomove-datenschutzbestimmungen.html
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='privacy-1'
        headline='Bekommen alle Mobilitätspartner in regiomove meine Daten?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Nein, denn nur die Mobilitätsanbieter, mit denen du dich verbunden hast (z.B.
              über die Profileinstellungen oder durch die Buchung bei einem der Anbieter),
              bekommen im Sinne der Datensparsamkeit auch nur diejenigen Daten, die
              erforderlich sind, um ihren jeweiligen Mobilitätsdienst anzubieten.
            </Typography>
            <Typography paragraph variant='caption'>
              Unter&nbsp;
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/unternehmen/rechtliche-hinweise/informationspflicht-nach-dsgvo/regiomove-datenschutzbestimmungen.html'>
                https://www.kvv.de/unternehmen/rechtliche-hinweise/informationspflicht-nach-dsgvo/regiomove-datenschutzbestimmungen.html
              </Link>
              &nbsp;kannst du nachlesen, welcher Anbieter welche Daten über regiomove erhält.
            </Typography>
          </>
        }
      />
    </>
  )
}

Privacy.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default Privacy
