import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
  Box,
  Grid,
  Link,
  Typography,
} from '@material-ui/core'

import General from 'components/Sections/General'
import Account from 'components/Sections/Account'
import BookingKVV from 'components/Sections/BookingKVV'
import BookingNextbike from 'components/Sections/BookingNextbike'
import BookingStadtmobil from 'components/Sections/BookingStadtmobil'
import BookingVoi from 'components/Sections/BookingVoi'
import BookingBikebox from 'components/Sections/BookingBikebox'
import Payment from 'components/Sections/Payment'
import Service from 'components/Sections/Service'
import Privacy from 'components/Sections/Privacy'
import RegiomovePorts from 'components/Sections/RegiomovePorts'
import Prepaid from 'components/Sections/Prepaid'

/**
 * Component for main content
 */
const Main = () => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Grid container direction='column' className={classes.middleContainer} justify='flex-start' alignItems='center' wrap='nowrap'>
      <Grid item className={classes.mainContainer}>
        <Box my={3}>
          <Link href='#general' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>Allgemein</Typography></Link>
          <Link href='#account' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>Nutzerkonto</Typography></Link>
          <Link href='#bookingKVV' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>Buchung KVV-Tickets und Allgemeines</Typography></Link>
          <Link href='#bookingNextbike' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>Buchung KVV.nextbike</Typography></Link>
          <Link href='#bookingStadtmobil' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>Buchung Stadtmobil</Typography></Link>
          <Link href='#bookingVoi' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>Buchung Voi</Typography></Link>
          <Link href='#bookingBikebox' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>Buchung Kienzler Bikeboxen</Typography></Link>
          <Link href='#payment' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>Bezahlung</Typography></Link>
          <Link href='#prepaid' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>Vorkasse</Typography></Link>
          <Link href='#service' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>Kundenservice und Reklamation</Typography></Link>
          <Link href='#privacy' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>Datenschutz</Typography></Link>
          <Link href='#regiomovePorts' underline='always'><Typography color='textPrimary' component='div' variant='subtitle1'>regiomove Ports</Typography></Link>
        </Box>
        <a className={classes.anchor} href='#general' id='general'>Allgemein</a>
        <Typography id='general' component='div' variant='h6'>Allgemein</Typography>
        <Box mt={2} mb={3}>
          <General expanded={expanded} handleChange={handleChange} />
        </Box>
        <a className={classes.anchor} href='#account' id='account'>Nutzerkonto</a>
        <Typography component='div' variant='h6'>Nutzerkonto</Typography>
        <Box mt={2} mb={3}>
          <Account expanded={expanded} handleChange={handleChange} />
        </Box>
        <a className={classes.anchor} href='#bookingKVV' id='bookingKVV'>Buchung KVV-Tickets und Allgemeines</a>
        <Typography component='div' variant='h6'>Buchung KVV-Tickets und Allgemeines</Typography>
        <Box mt={2} mb={3}>
          <BookingKVV expanded={expanded} handleChange={handleChange} />
        </Box>
        <a className={classes.anchor} href='#bookingNextbike' id='bookingNextbike'>Buchung KVV.nextbike</a>
        <Typography component='div' variant='h6'>Buchung KVV.nextbike</Typography>
        <Box mt={2} mb={3}>
          <BookingNextbike expanded={expanded} handleChange={handleChange} />
        </Box>
        <a className={classes.anchor} href='#bookingStadtmobil' id='bookingStadtmobil'>Buchung Stadtmobil</a>
        <Typography component='div' variant='h6'>Buchung Stadtmobil</Typography>
        <Box mt={2} mb={3}>
          <BookingStadtmobil expanded={expanded} handleChange={handleChange} />
        </Box>
        <a className={classes.anchor} href='#bookingVoi' id='bookingVoi'>Buchung Stadtmobil</a>
        <Typography component='div' variant='h6'>Buchung Voi</Typography>
        <Box mt={2} mb={3}>
          <BookingVoi expanded={expanded} handleChange={handleChange} />
        </Box>
        <a className={classes.anchor} href='#bookingBikebox' id='bookingBikebox'>Buchung Kienzler Bikeboxen</a>
        <Typography component='div' variant='h6'>Buchung Kienzler Bikeboxen</Typography>
        <Box mt={2} mb={3}>
          <BookingBikebox expanded={expanded} handleChange={handleChange} />
        </Box>
        <a className={classes.anchor} href='#payment' id='payment'>Bezahlung</a>
        <Typography component='div' variant='h6'>Bezahlung</Typography>
        <Box mt={2} mb={3}>
          <Payment expanded={expanded} handleChange={handleChange} />
        </Box>
        <a className={classes.anchor} href='#prepaid' id='prepaid'>Vorkasse</a>
        <Typography component='div' variant='h6'>Vorkasse</Typography>
        <Box mt={2} mb={3}>
          <Prepaid expanded={expanded} handleChange={handleChange} />
        </Box>
        <a className={classes.anchor} href='#service' id='service'>Kundenservice und Reklamation</a>
        <Typography component='div' variant='h6'>Kundenservice und Reklamation</Typography>
        <Box mt={2} mb={3}>
          <Service expanded={expanded} handleChange={handleChange} />
        </Box>
        <a className={classes.anchor} href='#privacy' id='privacy'>Datenschutz</a>
        <Typography component='div' variant='h6'>Datenschutz</Typography>
        <Box mt={2} mb={3}>
          <Privacy expanded={expanded} handleChange={handleChange} />
        </Box>
        <a className={classes.anchor} href='#regiomovePorts' id='regiomovePorts'>regiomove Ports</a>
        <Typography component='div' variant='h6'>regiomove Ports</Typography>
        <Box mt={2} mb={3}>
          <RegiomovePorts expanded={expanded} handleChange={handleChange} />
        </Box>

      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  // Position anchors, so headlines won't be hidden by app bar: https://stackoverflow.com/a/13184714
  anchor: {
    display: 'block',
    position: 'relative',
    visibility: 'hidden',
  },
  mainContainer: {
    flex: '1 0 auto',
    maxWidth: theme.raumo.layout.mainContainer.maxWidth,
    padding: theme.spacing(0, 2),
    width: '100%',
  },
  middleContainer: {
    backgroundColor: theme.palette.primary[50],
    minHeight: '100vh',
    maxWidth: theme.raumo.layout.middleContainer.maxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
  },
}))

export default Main
