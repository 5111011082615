import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import {
  Link,
  Typography,
} from '@material-ui/core'

const Account = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='account-0'
        headline='Wie erstelle ich einen Account in der App?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Zur Registrierung musst du einfach deine E-Mail-Adresse und deine Handynummer eingeben. Anschließend erhältst du von regiomove eine SMS sowie eine E-Mail mit Verifikations-Codes. Anschließend bitten wir dich noch einige persönliche Daten einzutragen, damit du die regiomove-Dienste nutzen kannst. Die Sicherheit deiner Daten genießt höchste Priorität.
              <br />
              Weitere Informationen zum Datenschutz findest du hier:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/unternehmen/rechtliche-hinweise/informationspflicht-nach-dsgvo/regiomove-datenschutzbestimmungen.html'>
                https://www.kvv.de/unternehmen/rechtliche-hinweise/informationspflicht-nach-dsgvo/regiomove-datenschutzbestimmungen.html
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='account-1'
        headline='Wie melde ich mich an?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Wenn du dich mit einem bestehenden regiomove-Konto anmelden möchtest,
              gib bitte deine Handynummer an. Wir schicken dir per SMS einen
              Code zu, den du anschließend in der Anmeldemaske eingeben musst.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='account-2'
        headline='Warum habe ich kein Passwort?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Um zu verhindern, dass dein Konto durch Dritte gehackt und missbraucht
              wird, haben wir bei regiomove ein besonders sicheres Anmeldeverfahren im
              Einsatz. Wir verwenden für die Anmeldung eine
              Zwei-Faktor-Authentifizierung, welche kein Passwort benötigt. Du
              bekommst bei jeder Anmeldung von uns einen neuen Code per SMS zur
              Authentifizierung geschickt. So musst du dir kein Passwort mehr
              merken.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='account-3'
        headline='Wie kann ich mein Konto kündigen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Du kannst dein Konto ganz einfach über die Kontoeinstellungen in der App kündigen. Nach der Kündigung erhältst du eine Bestätigungsemail.
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Hinweis:</strong>
              <br />
              Solltest du auch bei unseren Mobilitätspartnern Konten besitzen, so werden diese nicht
              gelöscht. Wenn du dennoch vorhandene Konten bei den einzelnen Anbietern löschen möchtest,
              wende dich bitte an den Kundenservice des jeweiligen Partners. Welche Konten du
              noch bei welchen Anbietern besitzt, steht in der Bestätigungsemail zu deiner Kündigung.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='account-4'
        headline='Ich habe eine andere Mail-Adresse/Handynummer, was kann ich tun?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Zum Ändern deiner E-Mail-Adresse oder Handynummer schreibe uns eine
              kurze E-Mail an <Link href='mailto:regiomove-support@kvv.karlsruhe.de'>regiomove-support@kvv.karlsruhe.de</Link>.
              Teile uns dann einfach deine User ID sowie die bisherige und die neue E-Mail-Adresse/Handynummer mit.
              Du erhältst nach erfolgter Änderung eine Rückmeldung.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='account-5'
        headline='Wie kann ich Favoriten anlegen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Über das Suche-Feld in der Karte kannst du beliebige Adressen eingeben oder
              beispielsweise Haltestellen und Stationen auswählen. Die sich öffnenden Details
              am unteren Bildschirmrand enthalten unter anderem einen „Favoriten-Stern“. Drücke
              diesen Stern und benenne den Favoriten ggf. vor dem Speichern um. Bei
              zukünftigen Routing-Anfragen werden deine Favoriten direkt und mit höherer Priorität bei der Suche vorgeschlagen.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='account-6'
        headline='Ich habe schon einen KVV.nextbike/stadtmobil/Voi/KVV-Account. Kann ich mich damit auch bei regiomove anmelden?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Nein, das geht leider nicht. Um regiomove nutzen zu können, brauchst du
              einen neuen regiomove-Account.
            </Typography>
            <Typography paragraph variant='caption'>
              Du kannst jedoch dein vorhandenes KVV.nextbike-Kundenkonto mit regiomove
              verknüpfen. Gehe hierzu im Menü auf Kontoverwaltung, in den Bereich "Ihre Mobilitäts-Konten" und gebe deine KVV.nextbike-Kontodaten ein. Solltest du ein KVV.nextbike-Abo besitzen,
              wird dies fortan bei der Preiskalkulation bei Fahrten mit dem KVV.nextbike berücksichtigt.
            </Typography>
            <Typography paragraph variant='caption'>
              Bei stadtmobil und Voi ist eine solche Kontoverknüpfung leider nicht möglich.
            </Typography>
          </>
        }
      />

      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='account-7'
        headline='Wie alt muss ich sein, um regiomove zu nutzen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Prinzipiell verlangt die Nutzung von regiomove keine Altersbeschränkung.
              Einzelne Anbieter setzen jedoch ein Mindestalter voraus:
            </Typography>
            <Typography paragraph variant='caption'>
              KVV - 7 Jahre
              <br />
              KVV.nextbike - 16 Jahre
              <br />
              stadtmobil - 18 Jahre
              <br />
              Voi - 18 Jahre
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='account-8'
        headline='Warum muss ich mich manchmal neu anmelden?'
        content={
          <>
            <Typography paragraph variant='caption'>
              In gewissen Abständen werden App-Updates eingespielt, um die Sicherheit zu gewährleisten und die Funktionen zu verbessern. Diese Updates machen es manchmal erforderlich, dass du dich erneut in der App anmelden musst. Meistens passieren die Updates aber im Hintergrund, ohne dass du es merkst.
            </Typography>
          </>
        }
      />
    </>
  )
}

Account.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default Account
