import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import {
  Link,
  Typography,
} from '@material-ui/core'

const BookingKVV = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingKVV-0'
        headline='Wie buche ich mit regiomove?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Mit regiomove kannst du auf verschiedene Weisen buchen. Voraussetzung
              ist lediglich, dass du ein gültiges Zahlungsmittel hinterlegt hast
              (Du erhältst automatisch eine Aufforderung zur Angabe einer
              Bezahlmethode).
            </Typography>
            <Typography paragraph variant='caption'>
              Zur Direktbuchung der einzelnen Mobilitätsanbieter gelangst du über das
              Menü oder das Icon „Buchen“. Oder du wählst auf dem Homescreen dein
              Ziel aus, entweder durch Markieren auf der Karte oder durch die Eingabe
              deines Ziels im Eingabefeld.
            </Typography>
            <Typography paragraph variant='caption'>
              Aus den angebotenen Routen kannst du anhand der Attribute Preis,
              Fahrzeit, Umstiege, Verkehrsmittel und CO2-Verbrauch das für dich
              passende Angebot auswählen und du gelangst zur Buchung.
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Tipp:</strong>
              <br />
              Unter „Favoriten“ und Mobilitätsprofil“ kannst du die Einstellungen so
              setzen, dass die vorgeschlagenen Ergebnisse ganz auf deine individuellen
              Bedürfnisse angepasst sind.
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Hinweis: Intermodale Buchungen</strong>
              <br />
              Bei der Buchung von Routen, die mehrere Verkehrsmittel umfassen, müssen
              die ÖPNV-Tickets erst direkt vor Fahrtantritt aktiviert werden. Auch die
              Leihe von KVV.nextbikes oder stadtmobil muss vor Fahrtantritt in der App
              bestätigt werden.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingKVV-1'
        headline='Wie nutze ich die einzelnen Mobilitätsangebote in der App?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Öffne das Menü. Du findest hier unter „Buchen“ eine Auflistung
              aller Mobilitätspartner, die aktuell in regiomove verfügbar sind. Du
              kannst auch einfach auf den „Buchen“ Reiter in der unteren Menüleiste
              klicken. Wenn Du einen Partner auswählst, kommst du zur Direktbuchung
              des Anbieters (Du verlässt die App dafür nicht).
            </Typography>
            <Typography paragraph variant='caption'>
              Du kannst auch die Mobilitätsangebote einfach direkt aus der
              Verbindungsauskunft heraus buchen.
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Tipp:</strong>
              <br />
              Du musst dich jedoch nicht gleich mit allen Mobilitätsdiensten
              verbinden. In den Profileinstellungen kannst du auswählen, welche
              Dienste für dich relevant sind und bei der Verbindungssuche
              berücksichtigt werden sollen.
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Hinweis:</strong>
              <br />
              stadtmobil kannst du erst nutzen, sobald du deinen Führerschein
              einmalig in einem stadtmobil-Büro persönlich vorgelegt haben.
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/privatkunden/so-funktionierts/'>
                https://karlsruhe.stadtmobil.de/privatkunden/so-funktionierts/
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingKVV-2'
        headline='Welche ÖPNV-Tickets kann ich in regiomove kaufen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Mit regiomove kannst du alle Einzel- und Tageskarten, Ergänzungskarten,
              Fahrradkarten und den Zuschlag 1. Klasse kaufen. Seit dem 20.12.2021
              ist der individuelle Monatstarif KVV.homezone über die App buchbar.
              Mehr Infos zur Homezone findest du unter&nbsp;
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/mobilitaet/kvvhomezone.html'>
                https://www.kvv.de/mobilitaet/kvvhomezone.html.<br />
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              Monats- und Jahreskarten sind über die App nicht erwerbbar – diese kannst
              du aber bequem bei KVV Abo-Online kaufen:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://abo.kvv.de/abo/'>https://abo.kvv.de/abo/</Link>
            </Typography>
            <Typography paragraph variant='caption'>
              Auch Bahncard-Besitzer und Inhaber eines KVV-Abos können diese geltend
              machen.
            </Typography>
            <Typography paragraph variant='caption'>
              Die aktuellen Tarife findest du hier:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/fahrkarten/fahrkarten-preise/einzelpersonen/einzelfahrkarte.html'>
                https://www.kvv.de/fahrkarten/fahrkarten-preise/einzelpersonen/einzelfahrkarte.html
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/fahrkarten/fahrkarten-preise/einzelpersonen/tageskarte.html'>
                https://www.kvv.de/fahrkarten/fahrkarten-preise/einzelpersonen/tageskarte.html
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingKVV-3'
        headline='Was ist die Homezone?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Die Homezone ist ein individueller Monatstarif. Zieh dir einen Kreis
              in der App und bestimme deinen eigenen Preis. Mehr Infos zur Homezone
              findest du unter&nbsp;
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/mobilitaet/kvvhomezone.html'>
                https://www.kvv.de/mobilitaet/kvvhomezone.html.<br />
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Hinweis:</strong>
              <br />
              Um in der App den Preis für eine Homezone angezeigt zu bekommen,
              musst du mit deinem Nutzerkonto angemeldet sein. Du kannst die Homezone
              aber auch einfach auf unserer Webseite ausprobieren:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/mobilitaet/kvvhomezone/web-version.html'>
                https://www.kvv.de/mobilitaet/kvvhomezone/web-version.html
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingKVV-4'
        headline='Kann ich ein Ticket für eine andere Person buchen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Ja, aber nur dann wenn die Person, die das Ticket gebucht hat, bei der
              Fahrt dabei ist.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingKVV-5'
        headline='Können auch Minderjährige Tickets kaufen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Kinder können beim KVV grundsätzlich ab 7 Jahren Tickets erwerben. In der digitalen Welt
              ist dies derzeit leider noch nicht möglich, da der Bezahldienstleister nur Bezahlmittel von voll
              geschäftsfähigen Personen ab 18 Jahren zulässt.
            </Typography>
            <Typography paragraph variant='caption'>
              Sollen Kinder mit eigenem Smartphone dennoch in die Lage versetzt werden, über die App
              KVV.regiomove Tickets zu kaufen, so können Eltern für ihre Kinder auf deren Smartphone
              ein Konto unter dem Namen und Geburtsdatum des Elternteils einrichten. Wichtig dabei ist,
              dass mit der Handynummer oder der E-Mail-Adresse kein weiteres regiomove-Konto
              existiert. Das Elternteil kann dann ein Bezahlmittel hinterlegen, über das das Kind die Tickets
              buchen und bezahlen kann. Die Einzel- und Tagestickets in der App sind deshalb derzeit
              nicht personenbezogen.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingKVV-6'
        headline='Ist das gebuchte Ticket auch offline in der App verfügbar?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Ja, im Menü findest du unter „Meine Buchungen“ deine gebuchten Tickets.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingKVV-7'
        headline='Kann ich den KVV.luftlinientarif auch in der regiomove-App kaufen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Nein, den Luftlinientarif kannst du derzeit nur über die Fairtiq-App
              mit dem Check-In Check-Out Verfahren erwerben.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingKVV-8'
        headline='Wie kann ich eine Buchung stornieren?'
        content={
          <>
            <Typography paragraph variant='caption'>
              <strong>KVV Tickets:</strong>
              <br />
              KVV Tickets können nicht in der App storniert werden. Solltest du einmal aus Versehen ein Ticket gebucht haben, dann kannst du das Ticket bei uns auch wieder stornieren lassen. Rufe dazu einfach bei unserem Kundenservice an oder schreibe eine Nachricht. Wir können dein Ticket stornieren und der Betrag wird dir wieder zurücküberwiesen.
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Was du nicht tun solltest:</strong>
              <br />
              Bitte verhindere nicht den Einzug des Ticketbetrags bei deiner Bank. Das führt in aller Regel dazu, dass du bei unserem Bezahldienstleister gesperrt wirst und im Nachgang eine Rückbuchung eingeleitet wird, durch die dir Rücklastschriftgebühren entstehen können.
              <br />
              <strong>Deshalb:</strong> Bei Storno, bitte den Kundenservice anrufen.

            </Typography>
            <Typography paragraph variant='caption'>
              <Link href='mailto:info@kvv.karlsruhe.de'>
                info@kvv.karlsruhe.de
              </Link>
              <br />
              <Link href='tel:+4972161075885'>0721 6107-5885</Link>
              <br />
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/service/kontakt/kontaktformular-anfrage.html'>
                https://www.kvv.de/service/kontakt/kontaktformular-anfrage.html
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingKVV-9'
        headline='Was kann ich tun, wenn ich kein Ticket buchen kann, obwohl ich korrekt angemeldet bin?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Prüfe zuallererst in den Kontoeinstellungen der App, ob dein Bezahlmittel korrekt hinterlegt ist. Prüfe außerdem deine Internetverbindung.
            </Typography>
            <Typography paragraph variant='caption'>
              Solltest du bei der Ticketbuchung trotzdem eine Fehlermeldung erhalten, obwohl du mit einem hinterlegten Bezahlmittel angemeldet bist, kann ein einfaches Ab- und wieder Anmelden in der App Abhilfe schaffen.
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Zusatzinfo:</strong><br />
              In gewissen Abständen werden App-Updates zur Gewährleistung der Sicherheit und der Verbesserung des Service eingespielt. Diese Updates machen es manchmal erforderlich, dass du dich erneut in der App anmelden musst. Meistens passieren die Updates aber im Hintergrund, ohne dass du es merkst.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingKVV-10'
        headline='Sehe ich Buchungen, die ich über die Apps der jeweiligen Mobilitätsdienstleister gebucht habe, auch in der regiomove App?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Nein. Regiomove zeigt nur Buchungen an, die auch in der regiomove App getätigt wurden. Diese Buchungen werden separat von regiomove abgerechnet. Buchungen, die du über die Anwendungen der Partner durchführst, werden direkt von diesen abgerechnet und sind der regiomove App nicht bekannt.
            </Typography>
          </>
        }
      />
    </>
  )
}

BookingKVV.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default BookingKVV
