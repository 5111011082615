import { useState } from 'react'

const useLargeTxt = () => {
  const [largeTxt] = useState(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const largeTxtParam = urlParams.get('largeTxt')
    return largeTxtParam === 'true'
  })
  return largeTxt
}

export default useLargeTxt
