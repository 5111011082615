import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import {
  Link,
  Typography,
} from '@material-ui/core'

const Service = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='service-0'
        headline='Ich habe Fragen zu meiner Fahrt/meiner Buchung. Wen kann ich fragen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Du kannst dich direkt an die Kundenzentren der jeweiligen Anbieter wenden oder
              direkt beim KVV-Kundenservice anfragen:
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>KVV.nextbike:</strong>
              <br />
              <Link href='mailto:kundenservice@nextbike.de'>
                kundenservice@nextbike.de
              </Link>
              <br />
              <Link href='tel:+493069205046'>030 69205046</Link>
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>stadtmobil:</strong>
              <br />
              <Link href='mailto:info@karlsruhe.stadtmobil.de '>
                info@karlsruhe.stadtmobil.de
              </Link>
              <br />
              <Link href='tel:+497219119110'>0721 911911-0</Link>
              <br />
              <Link target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/kontakt/'>
                https://karlsruhe.stadtmobil.de/kontakt/
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>KVV und regiomove:</strong>
              <br />
              <Link href='mailto:info@kvv.karlsruhe.de'>
                info@kvv.karlsruhe.de
              </Link>
              <br />
              <Link href='tel:+4972161075885'>0721 6107-5885</Link>
              <br />
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/service/kontakt/kontaktformular-anfrage.html'>
                https://www.kvv.de/service/kontakt/kontaktformular-anfrage.html
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='service-1'
        headline='Ich möchte noch etwas anderes sagen. An wen kann ich mich wenden?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Du kannst dein Anliegen in das Kontaktformular des KVV eintragen:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/service/kontakt/kontaktformular-anfrage.html'>
                https://www.kvv.de/service/kontakt/kontaktformular-anfrage.html
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              Ein Mitarbeiter des KVV wird sich in Kürze mit dir in Verbindung setzen.
            </Typography>
          </>
        }
      />
    </>
  )
}

Service.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default Service
