import {
  createMuiTheme,
} from '@material-ui/core/styles'

/**
 * Creates the theme for material ui
 */

export const createTheme = (largeTxt) => {
  // create basic theme
  const theme = createMuiTheme({
    // overrides
    palette: {
      primary: {
        // regiomove blue variants
        main: '#12297A',
        50: '#EAEEFB',
      },
    },
    mixins: {
      toolbar: {
        minHeight: 56,
      },
    },
    typography: {
      ...(largeTxt === true ? { fontSize: 28 } : {}),
    },
    // additional theme variables
    raumo: {
      layout: {
        middleContainer: {
          maxWidth: 1400,
        },
        mainContainer: {
          maxWidth: 1200,
        },
      },
    },
  })

  // component overrides which make use of theme properties go here
  theme.overrides = {
    ...theme.overrides,
    MuiAccordionDetails: {
      root: {
        flexDirection: 'column',
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(2),
      },
    },
  }
  return theme
}
