import React from 'react'

import {
  CssBaseline,
} from '@material-ui/core'

import Main from 'components/Main'

const Layout = () => {
  return (
    <>
      <CssBaseline />
      <Main />
    </>
  )
}

export default Layout
