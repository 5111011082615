import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import { Link, Typography } from '@material-ui/core'

const Payment = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='payment-0'
        headline='Wie füge ich ein Zahlungsmittel hinzu?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Um ein Zahlungsmittel hinzufügen zu können, musst du vorab persönliche Daten
              wie Name, Adresse und Geburtsdatum vollständig in der Kontoverwaltung
              angelegt haben.
              <br />
              <br />
              Über die Schaltfläche “Zahlungsmittel hinzufügen“ in der Kontoverwaltung wirst
              du zu einer sicheren Eingabemaske von LOGPAY weitergeleitet. Dort kannst du
              zwischen der Bezahlung per SEPA-Lastschrift, Paypal oder per Kreditkarte
              wählen. Deine persönlichen Daten werden in der Eingabemaske automatisch
              übernommen. Bitte überprüfen daher deine Angaben auf Richtigkeit und ändere
              diese gegebenenfalls im Menüpunkt „Persönliche Daten“ ab.
              <br />
              <br />
              Im Anschluss fülle bitte die nötigen Pflichtfelder aus und bestätige zunächst mit
              „Zahlart prüfen“ und im Anschluss mit „Zahlart speichern“.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='payment-1'
        headline='Wie kann ich mein Zahlungsmittel ändern oder bearbeiten?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Einmal angelegte Zahlungsmittel können nachträglich nicht mehr bearbeitet
              werden. Es kann nur bearbeitet bzw. gewählt werden, welches Zahlungsmittel
              aktiv ist. Füge bei Bedarf bitte zuerst ein neues Zahlungsmittel hinzu, markiere
              das gewünschte Zahlungsmittel als „Aktives Zahlungsmittel“ und entferne
              anschließend gegebenenfalls nicht mehr benötigte inaktive Zahlungsmittel.
              <br />
              <br />
              Für das Anlegen eines Zahlungsmittels werden die persönlichen Daten aus der
              Kontoverwaltung übergeben. Wenn du z.B. deine Anschrift ändern möchtest,
              bearbeite diese zuvor im Menüpunkt "Persönlichen Daten” in der
              Kontoverwaltung. Im Anschluss kannst du ein neues Zahlungsmittel anlegen und
              als aktives Zahlungsmittel auswählen.
              <br />
              <br />
              Hinweis: Es <strong>muss</strong> immer genau ein Zahlungsmittel als aktives ausgewählt sein.
              Daher kann ein aktives Zahlungsmittel nicht gelöscht werden. Inaktive
              Zahlungsmittel können einfach über das entsprechende „Mülleimer-Symbol“
              entfernt werden.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='payment-2'
        headline='Erhalte ich eine Rechnung?'
        content={
          <>
            <Typography paragraph variant='caption'>
              In der App kannst du die Buchungsübersicht einsehen, in der du genau siehst,
              welche Leistungen du wann bei welchem Anbieter erworben hast. Nach deiner
              Buchung in der App wird dir als Buchungsbeleg außerdem eine E-Mail zugesandt.
              Für den Kauf von KVV-Fahrscheinen genügt diese E-Mail umsatzsteuerlichen
              Anforderungen und dient dir gleichsam als Rechnung.
              <br />
              <br />
              Bitte beachte: Bei Buchungen von Leistungen bei anderen Mobilitätsanbietern
              (z.B: Bike- oder Carsharing), erfüllt die Bestätigungsmail nicht den Tatbestand
              einer Rechnung. Wende dich hierfür bitte an unsere jeweiligen Mobilitätspartner.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='payment-3'
        headline='Wie kann ich in regiomove bezahlen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Du kannst Zahlungen per SEPA-Lastschrift, Paypal, Vorkasse und per Kreditkarte über
              unseren Zahlungsanbieter LOGPAY vornehmen. Du musst dazu die notwendigen
              Bezahldaten angeben, indem du ein Zahlungsmittel anlegst. Die Bezahlmethode
              kann auch nachträglich durch das Anlegen eines neuen Zahlungsmittels geändert
              werden.
              <br />
              <br />
              Die Abwicklung der Zahlungen läuft komplett über den Zahlungsanbieter LOGPAY
              Financial Services, ein spezialisiertes Unternehmen für Finanztransaktionen im
              Mobilitätssektor. Deine Bezahldaten liegen dabei geschützt auf Servern von
              LOGPAY.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='payment-4'
        headline='Wie kann ich mein Zahlungsmittel löschen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Du benötigst bei regiomove immer ein aktives Zahlungsmittel, da eventuelle
              Abbuchungen zeitversetzt stattfinden können. Daher kann ein aktives
              Zahlungsmittel nicht gelöscht werden. Nur wenn du mehrere Zahlungsmittel
              hinterlegt hast können inaktive Zahlungsmittel über das entsprechende
              „Mülleimer-Icon“ entfernt werden.
              <br />
              <br />
              Falls du also nur ein Zahlungsmittel angelegt hast, welches aktiv ist und gelöscht
              werden soll, musst du zuerst ein neues aktives Zahlungsmittel anlegen bevor du
              das bisherige löschen kannst.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='payment-5'
        headline='Ich habe Probleme beim Hinterlegen meiner Kreditkarte oder dem 3-D-Secure-Verfahren'
        content={
          <>
            <Typography paragraph variant='caption'>
              Seit Ende 2020 benutzen einige Kreditkartenausgeber das 3-D Secure-Verfahren,
              das die Sicherheit bei Online-Kreditkarten-Bezahlungen erhöhen soll. Dabei
              kommt es immer wieder zu Rückfragen der Kund*innen. Auf der Hilfe-Seite von
              LOGPAY sind die wichtigsten Antworten zu den Fragen rund um die
              Kreditkartenzahlung und 3-D Secure übersichtlich aufgeführt:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link
                target='_blank'
                rel='noopener'
                href='https://faq.logpay.de/'
              >
                https://faq.logpay.de/
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='payment-6'
        headline='Ich habe weitere Fragen zur Abbuchung und Rechnung. An wen kann ich mich wenden?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Unser Zahlungsanbieter LOGPAY hält alle weiteren Antworten zu deinen Fragen unter folgendem Link bereit:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link
                target='_blank'
                rel='noopener'
                href='https://faq.logpay.de/'
              >
                https://faq.logpay.de/
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              Du erreichst den LOGPAY-Kundenservice unter:<br />
              Tel: +49 6196 8012 702<br />
              E-Mail: <Link href='mailto:kundenservice@logpay.de'> Kundenservice@logpay.de</Link>
            </Typography>

          </>
        }
      />
    </>
  )
}

Payment.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default Payment
