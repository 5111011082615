import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import {
  Link,
  Typography,
} from '@material-ui/core'

const BookingNextbike = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingNextbike-0'
        headline='Wie gebe ich mein nextbike zurück?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Das nextbike kann nur an offiziellen Stationen oder innerhalb einer Flexzone gut einsehbar an jeder öffentlichen Straße zurückgegeben werden. Auf der Standortkarte in der regiomove-App sieht man die Stationen bzw. bis wohin die Flexzone reicht.
              <br />
              Nach manuellem Schließen des Schlosses wird das Rad automatisch zurückgegeben. Ein Piepton bestätigt die Rückgabe. Sollte kein Ton zu hören sein, konnte die Rückgabe nicht korrekt erfolgen. Bitte drücke den Hebel vom Schloss erneut, bis es einrastet und der Ton zu hören ist oder wende dich an den Kundenservice.
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>KVV.nextbike:</strong>
              <br />
              <Link href='mailto:kundenservice@nextbike.de'>
                kundenservice@nextbike.de
              </Link>
              <br />
              <Link href='tel:+493069205046'>030 69205046</Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingNextbike-1'
        headline='Wie kann ich eine Buchung stornieren?'
        content={
          <>
            <Typography paragraph variant='caption'>
              KVV.nextbikes sind bis zu 30 min reservierbar. Danach verfällt die
              Reservierung und das Rad wird wieder freigegeben. Für verfallene
              Reservierungen kann KVV.nextbike ggf. Kosten erheben.
            </Typography>
            <Typography paragraph variant='caption'>
              <Link href='mailto:kundenservice@nextbike.de'>
                kundenservice@nextbike.de
              </Link>
              <br />
              <Link href='tel:+493069205046'>030 69205046</Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingNextbike-2'
        headline='Welche Tarife gelten in regiomove?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Es gelten in regiomove die Tarife von KVV.nextbike:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://www.kvv-nextbike.de/de/karlsruhe/preise/'>
                https://www.kvv-nextbike.de/de/karlsruhe/preise/
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingNextbike-3'
        headline='Ich habe schon ein Konto/Abo bei KVV.nextbike. Kann ich das auch auf regiomove übertragen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Ein Jahresabo von KVV.nextbike wird in der regiomove-App im Endpreis der Buchung
              berücksichtigt, sofern das entsprechende KVV.nextbike-Konto in regiomove verknüpft wurde.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingNextbike-4'
        headline='Warum gibt es manchmal zeitliche Verzögerungen beim Öffnen von nextbike über die App?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Das ist ganz normal. Das Öffnen der Bikes kann aus technischen Gründen einige Sekunden dauern. Sollte das Öffnen bzw. Schließen auch nach kurzer Wartezeit mal nicht funktionieren, bewege leicht den Schlossbügel und drücken in der App den Knopf „Schloss öffnen“. Sollte auch das nicht helfen, wende dich bitte an den Kundenservice.
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>KVV.nextbike:</strong>
              <br />
              <Link href='mailto:kundenservice@nextbike.de'>
                kundenservice@nextbike.de
              </Link>
              <br />
              <Link href='tel:+493069205046'>030 69205046</Link>
            </Typography>
          </>
        }
      />
    </>
  )
}

BookingNextbike.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default BookingNextbike
