import React from 'react'

import {
  createTheme,
} from 'util/theme'

import {
  ThemeProvider,
} from '@material-ui/core/styles'

import Layout from 'components/Layout'
import useLargeTxt from 'hooks/useLargeTxt'

function App () {
  const largeTxt = useLargeTxt()
  return (
    <ThemeProvider theme={createTheme(largeTxt)}>
      <Layout />
    </ThemeProvider>
  )
}

export default App
