import React from 'react'
import PropTypes from 'prop-types'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'

const MuiAccordion = (props) => {
  const { id, headline, content, expanded, handleChange } = props
  return (
    <Accordion expanded={expanded === id} onChange={handleChange(id)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Typography variant='subtitle2'>{headline}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {content}
      </AccordionDetails>
    </Accordion>
  )
}

MuiAccordion.propTypes = {
  // Identifier
  id: PropTypes.string.isRequired,
  // Headline
  headline: PropTypes.string.isRequired,
  // Content node shown when expanded
  content: PropTypes.node.isRequired,
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,

}

export default MuiAccordion
