import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import {
  Link,
  Typography,
} from '@material-ui/core'

const BookingStadtmobil = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingStadtmobil-0'
        headline='Muss ich in regiomove auch eine Kaution zahlen, wenn ich Stadtmobil nutzen möchte?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Nein. Der Vorteil bei KVV.regiomove ist, dass du auch Stadtmobil-Fahrzeuge mieten
              kannst, ohne eine Kaution oder eine monatliche Teilnehmergebühr zu zahlen. Der Fahrpreis
              in regiomove setzt sich zusammen aus einem km-Tarif, einem Zeittarif sowie einer
              Grundgebühr je Fahrt. Du bezahlst also nur, was du fährst und kannst bei deiner
              Entscheidung für ein Mobilitätsangebot ganz spontan bleiben.
            </Typography>
            <Typography paragraph variant='caption'>
              Die regiomove-Tarife für Stadtmobil findest du&nbsp;
              <Link
                target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/media/user_upload/downloads_privatkunden/karlsruhe/regiomove/regiomove-stadtmobil_Tarife_Gebuehren.pdf'
              >
                <u>hier</u>.
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingStadtmobil-1'
        headline='Wie kann ich bei Stadtmobil meinen Führerschein vorzeigen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Wenn du in regiomove ein Fahrzeug bei Stadtmobil buchen möchtest, musst du bei Stadtmobil einen gültigen Führerschein vorweisen. Zuerst trage bitte in der App die erforderlichen Daten unter Kontoverwaltung / stadtmobil ein. Anschließend kannst du deinen Führerschein in der Geschäftsstelle von Stadtmobil vorlegen:
            </Typography>
            <Typography paragraph variant='caption'>
              Ludwig-Wilhelm-Straße 15, 76131 Karlsruhe
            </Typography>
            <Typography paragraph variant='caption'>
              Du benötigst dafür deinen Führerschein, Personalausweis oder Reisepass mit Meldebescheinigung. Der Vorgang dauert ca. 10 Minuten. Danach ist dein Konto für Stadtmobil-Buchungen freigeschaltet.
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Corona-Hinweis:</strong>
              <br />Bitte beachte, dass während der Pandemie die Stadtmobil-Geschäftsstelle nur nach Terminvereinbarung zwischen 10 und 17 Uhr geöffnet ist. Termine vereinbarst du entweder telefonisch <br />
              <Link target='_blank' rel='noopener' href='tel:0721-9119110'>
                0721-9119110<br />
              </Link>
              oder per Email&nbsp;<br />
              <Link target='_blank' rel='noopener' href='mailto:info@karlsruhe.stadtmobil.de'>
                info@karlsruhe.stadtmobil.de <br />
              </Link>
            </Typography>
            <Typography paragraph variant='caption'>
              Du kannst auch einen Termin zur Freischaltung per Video vereinbaren. Dazu wird dir ein Google Meets Link per E-Mail zugeschickt. Weitere Infos findest du hier:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/media/user_upload/downloads_privatkunden/karlsruhe/okw/Fuehrerscheinkontrolle_Anleitung.pdf'>
                https://karlsruhe.stadtmobil.de/media/user_upload/downloads_privatkunden/karlsruhe/okw/Fuehrerscheinkontrolle_Anleitung.pdf
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingStadtmobil-2'
        headline='Werden auch „alte“ Führerscheine verifiziert?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Ja, auch ältere Führscheine (nicht im Scheckkartenformat) werden
              verifiziert. Ebenso sind alle EU-Führerscheine verifizierbar und in
              regiomove gültig. Für die Nutzung von stadtmobil muss der Führerschein
              einmalig an einer Geschäftsstelle von stadtmobil persönlich vorgezeigt
              werden.
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/privatkunden/so-funktionierts/'>
                https://karlsruhe.stadtmobil.de/privatkunden/so-funktionierts/
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingStadtmobil-3'
        headline='Ist die stadtmobil-Buchung beendet, sobald ich das Fahrzeug zurückgebe?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Ja, sobald du die Buchung über die regiomove-App beendest, wird das Fahrzeug wieder freigegeben. Für die restliche Buchungszeit erhältst du eine Gutschrift in Höhe des halben Zeittarifs.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingStadtmobil-4'
        headline='Warum gibt es manchmal zeitliche Verzögerungen beim Öffnen oder Schließen von stadtmobil über die App?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Das Öffnen oder Schließen von stadtmobil-Fahrzeugen beim Starten oder Beenden der Buchung kann aus technischen Gründen einige Sekunden dauern (im seltenen Extremfall auch mal mehr als eine Minute). Sollte das Öffnen bzw. Schließen auch nach kurzer Wartezeit mal nicht funktionieren, wende dich bitte an den Kundenservice.
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>stadtmobil:</strong>
              <br />
              <Link href='mailto:info@karlsruhe.stadtmobil.de'>
                info@karlsruhe.stadtmobil.de
              </Link>
              <br />
              <Link href='tel:+497219119110'>0721 911911-0</Link>
              <br />
              <Link target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/kontakt/'>
                https://karlsruhe.stadtmobil.de/kontakt/
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingStadtmobil-5'
        headline='Wie kann ich eine Buchung stornieren?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Wenn du eine stadtmobil-Buchung verkürzen oder stornieren willst, kannst du dies bis 24 Stunden vor Buchungsbeginn kostenfrei durchführen. Stornierungskosten fallen immer dann an, wenn weniger als 24 Stunden vor Buchungsbeginn Buchungszeiträume vollständig oder teilweise weggenommen werden. Ebenso, wenn Buchungszeiträume von bereits laufenden Buchungen verkürzt werden. Die Stornierungskosten betragen grundsätzlich die Hälfte der wegfallenden Nutzungskosten zzgl. 1,- € Stornogebühr. Für Buchungsverlängerungen gibt es grundsätzlich keine zusätzlichen Gebühren (siehe&nbsp;
              <Link target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/media/user_upload/downloads_privatkunden/karlsruhe/regiomove/regiomove-stadtmobil_Tarife_Gebuehren.pdf'>
                regiomove-stadtmobil Tarife und Gebühren
              </Link>
              ).
            </Typography>
            <Typography paragraph variant='caption'>
              <Link href='mailto:info@karlsruhe.stadtmobil.de'>
                info@karlsruhe.stadtmobil.de
              </Link>
              <br />
              <Link href='tel:+497219119110'>0721 911911-0</Link>
              <br />
              <Link target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/kontakt/'>
                https://karlsruhe.stadtmobil.de/kontakt/
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingStadtmobil-6'
        headline='Ich bin bereits Stadtmobil-Kunde. Kann ich dann auch über regiomove Stadtmobil-Fahrzeuge buchen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Wenn du bereits Stadtmobil-Kunde bist, kannst du deine stadtmobil-Fahrten auch über die regiomove-App planen und dich zum Buchen in die stadtmobil-App weiterleiten lassen. Das Buchen in der regiomove-App ist jedoch nur mit einem neuen stadtmobil-Konto in deinem regiomove-Account möglich. Hier gelten dann die&nbsp;
              <Link
                target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/media/user_upload/downloads_privatkunden/karlsruhe/regiomove/regiomove-stadtmobil_Tarife_Gebuehren.pdf'
              >
                <u>regiomove-Tarife</u>.
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingStadtmobil-7'
        headline='Kann ich auch Stadtflitzer und Transporter über die regiomove-App buchen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Nein, leider nicht. Diese Fahrzeugtypen sind über KVV.regiomove nicht verfügbar.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingStadtmobil-8'
        headline='Welche Tarife gelten in regiomove?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Für Buchungen eines stadtmobils über regiomove gelten die eigens von
              stadtmobil festgelegten regiomove-Tarife.
            </Typography>
            <Typography paragraph variant='caption'>
              Aber für stadtmobil-Buchungen über regiomove ist <u>keine</u> monatliche
              Teilnehmergebühr oder Kaution erforderlich. Damit ist die Nutzung von
              Stadtmobil über regiomove ideal für Gelegenheitsnutzer!
            </Typography>
            <Typography paragraph variant='caption'>
              <strong>Tarife:</strong>
              <br />
              <Link target='_blank' rel='noopener' href='https://karlsruhe.stadtmobil.de/media/user_upload/downloads_privatkunden/karlsruhe/regiomove/regiomove-stadtmobil_Tarife_Gebuehren.pdf'>
                https://karlsruhe.stadtmobil.de/media/user_upload/downloads_privatkunden/karlsruhe/regiomove/regiomove-stadtmobil_Tarife_Gebuehren.pdf
              </Link>
            </Typography>
          </>
        }
      />
    </>
  )
}

BookingStadtmobil.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default BookingStadtmobil
