import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import {
  Typography,
} from '@material-ui/core'

const RegiomovePorts = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='regiomovePorts-0'
        headline='Was sind die Ports?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Die regiomove-Ports sind ein regionales Bauwerk, das dazu beitragen soll, neue
              Mobilitätsformen im Leben der Menschen zu integrieren. Das Projekt wurde 2022
              abgeschlossen und vom Wirtschaftsministerium Baden-Württemberg sowie dem
              Europäischen Fonds für Regionale Entwicklung gefördert.
            </Typography>
            <Typography paragraph variant='caption'>
              An den Ports werden multimodale Angebote auch im „echten Leben“ verknüpft,
              die in der regiomove-App buchbar sind. An verschiedenen Orten in der Region
              können Bus, Bahn, Tram, Car- und/oder Bikesharing gebucht werden. Mit diesen
              Mobilitätsknoten soll auf lange Sicht der Ausbau alternativer vorangebracht
              werden. D.h. mehr ÖPNV, Carsharing, mehr Bikesharing, mehr Angebote als
              sinnvolle Ergänzung oder Alternative zum privaten PKW.
            </Typography>
            <Typography paragraph variant='caption'>
              Die einzelnen Module an den Standorten bringen die einzelnen Mobilitätsdienste
              visuell zusammen. Und auch in der Region soll so ein Bewusstsein für neue und
              nachhaltige Mobilität geschaffen werden. Die Bewusstseinsbildung ist ein
              wichtiger Baustein auf dem Weg hin zur Integration neuer Fortbewegungsformen
              in unseren Alltag.
            </Typography>
            <Typography paragraph variant='caption'>
              An den Ports werden nicht nur Mobilitätsangebote gebündelt, sondern auch
              Services wie Infoterminals, Fahrradreparaturstationen, Schließfächer oder
              Bikeboxen angeboten. Jeder Port ist anders und hat seine eigenen Angebote.
              Zudem sind die Module meist mit Solar-Panels ausgestattet. Der produzierte
              Strom wird direkt ins Stromnetz eingespeist.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='regiomovePorts-1'
        headline='Was bedeuten die Farben?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Die Farben der Gläser geben einen Hinweis auf die Art des Mobilitätsangebots:
              Grün für Zweiräder, blau für PKW und violett für ÖPNV. Info-Stellen sind mit
              einem rötlichen Farbton erkennbar.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='regiomovePorts-2'
        headline='Welche Informationen finde ich im Info-Terminal?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Neben den „Klassikern“ Fahrplanauskunft und Störungsmeldungen lässt sich in
              der Karte eine Reise zu jedem Ort planen und bei Bedarf auch in die App
              übertragen, um die Fahrt buchen zu können.
            </Typography>
            <Typography paragraph variant='caption'>
              Im Terminal sind lokale und regionale Orte von den jeweiligen Kommunen
              eingepflegt. Die Nutzer*innen können sich hier über die Angebote vor Ort und
              über die Möglichkeiten zur Nutzung informieren, eine Anreise planen oder einfach
              nur die Zeit vertreiben und durch die Region stöbern.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='regiomovePorts-3'
        headline='Wo finde ich die Ports?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Im EFRE-Leuchtturmprojekt wurden 7 Pilot-Standorten Mobilitätsstationen gebaut:
              <ul>
                <li>Bahnhof Baden-Baden Oos</li>
                <li>Bahnhof Bretten</li>
                <li>Bahnhof Bühl</li>
                <li>Stadtbahnhof Ettlingen</li>
                <li>Bahnhof Graben-Neudorf</li>
                <li>Bahnhof Karlsruhe Hagsfeld</li>
                <li>Bahnhof Rastatt</li>
              </ul>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='regiomovePorts-4'
        headline='Gibt es noch weitere Ports in der Region?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Das Verkehrsministerium Baden-Württemberg möchte in den kommenden Jahren
              den Ausbau von Mobilitätsknoten fördern. Der KVV unterstützt dieses Vorhaben
              mit motivierten Kommunen und Unternehmen, um das Mobilitätsangebot
              flächendeckend auszubauen und näher zu den Menschen zu bringen.
            </Typography>
          </>
        }
      />
    </>
  )
}

RegiomovePorts.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default RegiomovePorts
