import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from 'components/MuiAccordion'

import {
  Link,
  Typography,
} from '@material-ui/core'

const BookingVoi = (props) => {
  const { handleChange, expanded } = props
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingVoi-0'
        headline='Wie miete ich einen Voi-E-Roller?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Auf der Karte werden alle verfügbaren Voi-Roller angezeigt. Finde einfach den
              entsprechenden Roller und entsperre ihn mit der App, indem du den QR-Code
              abscannst. Der Tarif wird in der App angezeigt. Dann kannst du schon losfahren.
              Man braucht etwas Geschwindigkeit, bevor man am Roller den Go-Button
              betätigen kann.
            </Typography>
            <Typography paragraph variant='caption'>
              Wir empfehlen dir zu deinem eigenen Schutz das Tragen eines Helms. Fahre
              immer nur allein auf einem Roller und beachte die gesetzlichen Vorschriften!
              Bleibe auf den Fahrradwegen oder auf der Straße nahe dem Bordstein.
            </Typography>
            <Typography paragraph variant='caption'>
              Beachte außerdem, dass es genau vorgegebene Voi-Parkzonen gibt, die du dir in
              der App anzeigen lassen kannst. Nur innerhalb dieser Zonen kann die Miete eines
              Rollers beendet werden.
            </Typography>
            <Typography paragraph variant='caption'>
              Wenn du dein Ziel erreicht hast, stelle den Roller vorschriftsmäßig ab und beende
              die Miete über die App. Danach steht der Roller wieder für andere Personen zur
              Verfügung. Blockiere keine öffentlichen Wege und sei umsichtig mit Fußgängern.
            </Typography>
            <Typography paragraph variant='caption'>
              Weitere Infos findest du unter:
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='https://www.voiscooters.com/de/wie-faehrt-man-voi/'>
                https://www.voiscooters.com/de/wie-faehrt-man-voi/
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingVoi-1'
        headline='Wie kann ich eine Buchung stornieren?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Schreibe bitte an unseren KVV-Kundenservice eine Nachricht mit den folgenden Infos:
              <ul>
                <li>Datum und Zeit der Fahrt</li>
                <li>Name und Email-Adresse</li>
                <li>Grund für die Stornierung</li>
                <li>Fahrzeug-Nummer</li>
              </ul>
            </Typography>
            <Typography paragraph variant='caption'>
              <Link target='_blank' rel='noopener' href='mailto:support@voiapp.io'>
                support@voiapp.io  <br />
              </Link>
              <Link target='_blank' rel='noopener' href='https://www.kvv.de/service/kontakt/kontaktformular-anfrage.html'>
                https://www.kvv.de/service/kontakt/kontaktformular-anfrage.html
              </Link>
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingVoi-2'
        headline='Ich habe schon ein Konto bei Voi. Kann ich das auch auf regiomove übertragen?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Ein vorhandenes Voi-Konto kann leider nicht mit regiomove verknüpft werden.
              Für Voi-Buchungen gilt also immer der Standard-Tarif von Voi.
            </Typography>
          </>
        }
      />
      <MuiAccordion
        expanded={expanded}
        handleChange={handleChange}
        id='bookingVoi-3'
        headline='Was kostet eine Voi-Miete?'
        content={
          <>
            <Typography paragraph variant='caption'>
              Der Preis hängt von mehreren Faktoren ab: Eine Grundgebühr je Entsperrung und
              ein minutenbasierter Zeittarif, der von Stadt, Tag oder Uhrzeit unterschiedlich
              sein kann.  Voi behält sich vor, die Preise anzupassen. Den aktuellsten Preis
              bekommst du aber immer in der App angezeigt.
            </Typography>
          </>
        }
      />
    </>
  )
}

BookingVoi.propTypes = {
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
}

export default BookingVoi
